
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      lastTimeStamp: Date.now()
    };
  }, // data
  mounted() {
    Vue.prototype?.$mixpanel?.track('location_report', {
      referrer: document.referrer
    });
  },
  methods: {
    refreshPage() {
      this.lastTimeStamp = Date.now();
    }
  }
});
